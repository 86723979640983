import { useState } from 'react';
import { downloadBlobFileOnClick } from '../helpers';
import api from '../api/api';
import { useApi } from './useApi';

export const useDownloadFile = ({ url, fileName, setLoading }) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = async (prop) => {
    const { data } = await api({ url: url || prop.url, responseType: 'blob' });

    if (prop.download !== false) downloadBlobFileOnClick(data, fileName);

    return data;
  };

  const { defaultRequest } = useApi({
    setIsLoading: setLoading || setIsLoading,
    request: downloadFile,
  });

  return { makeRequest: defaultRequest, isLoading };
};
