// @ts-nocheck
import { Alert, Box } from '@mui/material';
import React, { memo } from 'react';
import { getApplicationEnumsRequest } from '../../api/applicationsApi';
import { getReferencesRequest } from '../../api/referenceApi';
import { getSubjectRequest } from '../../api/subjectApi';
import { getWasteTypesRequest } from '../../api/wasteTypeApi';
import { OWNERSHIP_TYPE_TRANSLATE } from '../../constants';
import {
  ENTERPRISE_ACTIVITY_TYPES_ENUM,
  HAZARD_CLASS_ENUM,
  WASTE_MANAGEMENT_TYPE_IDS,
} from '../../constants/enums';
import {
  getInitialPlatforms,
  getInitialWasteProduct,
} from '../../constants/lkCadastr/cadastrForm1';
import {
  flexGap2, flexRowGap2, fullWidth,
} from '../../constants/lkCadastr/cadastrStorage';
import { OBJECTIVES_URL } from '../../constants/urls';
import { compareId } from '../../helpers';
import { formatEnumToSelectOption } from '../../helpers/entityHelper';
import { formatObjectWithAddress, getOktmoLabel } from '../../helpers/stringHelper';
import useCadastreTabs from '../../hooks/useCadastreTabs';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import AddButton from '../Buttons/AddButton';
import DeleteButton from '../Buttons/DeleteButton';
import CustomAccordion from '../CustomAccordion';
import CustomDatePicker from '../CustomDatePicker';
import CustomRadioGroup from '../CustomRadio';
import CustomInput from '../Inputs';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../Inputs/CustomAutocomplete';
import Selector from '../Selectors/Selector';
import NavTabs from '../Tabs';
import { Text, TextTitle } from '../Text';
import { CadastreForm1Platform } from './CadastreForm1Platform';

function CadastreForm1Building({
  building,
  deleteForm1Entity,
  objectTypeIds,
  wasteExcludeAccumulationParams,
  enterpriseAccumulationParam,
  buildingIndex,
  changeItemData,
  isDemo,
  buildings,
  openDeleteModal,
  changeItemFormByField,
  subjectAttributes,
  setCreateObjectIndex,
}) {
  const { tabs: platformTabs, tab: platformTab, setTab: setPlatformTab } = useCadastreTabs({
    items: building.platforms,
    tabName: 'Площадка',
  });

  const { tabs: wasteTabs, tab: wasteTab, setTab: setWasteTab } = useCadastreTabs({
    items: building.waste_products,
    tabName: 'Отход',
  });

  const oktmoAttributes = building.objective_id?.address_attributes?.attributes?.oktmo_attributes?.attributes;

  const onDeleteBuilding = async () => {
    const cloneArray = buildings.slice();

    if (building.id) {
      const deletedId = await deleteForm1Entity({ buildingId: building.id });

      if (!deletedId) return;
    }

    cloneArray.splice(buildingIndex, 1);

    changeItemFormByField({
      field: 'form1.items',
      value: cloneArray,
      type: 'delete',
    });
  };

  return (
    <Box sx={flexGap2}>
      <Box sx={flexRowGap2}>
        <TextTitle h2>
          Здание/Помещение/Сооружение №
          {buildingIndex + 1}
        </TextTitle>
        {!isDemo && buildings.length > 1 && (
          <DeleteButton onClick={() => openDeleteModal(onDeleteBuilding, buildingIndex, 'Здание/Помещение/Сооружение')}>
            Удалить Здание/Помещение/Сооружение
          </DeleteButton>
        )}
      </Box>
      <AsyncAutocomplete
        required
        useDefaultFilter
        validImmediately={!compareId(building.objective_id?.label, 0)}
        debounceTime={0}
        disabled={isDemo}
        shouldRequest={wasteExcludeAccumulationParams[WASTE_MANAGEMENT_TYPE_IDS].length}
        label="Адрес"
        sx={fullWidth}
        optionField={{
          label: 'label',
        }}
        request={(params) => getSubjectRequest({
          id: subjectAttributes.id,
          url: OBJECTIVES_URL,
          params: {
            ...params,
            ...wasteExcludeAccumulationParams,
          },
        })}
        additionalFilter={(options) => options.map((option) => ({
          ...option,
          label: formatObjectWithAddress({ objective: option }),
        }))}
        noOptionComponent={(
          <AddButton
            variant="outlined"
            onClick={() => setCreateObjectIndex({ buildingIndex })}
          >
            Создать объект
          </AddButton>
        )}
        value={building.objective_id}
        onChange={(e, value) => changeItemData('objective_id', value)}
      />
      <CustomInput
        label="Код ОКТМО"
        disabled
        debounceTime={0}
        value={getOktmoLabel(oktmoAttributes)}
        sx={{ width: '100%' }}
      />
      <CustomInput
        validImmediately
        debounceTime={0}
        value={building.building}
        disabled={isDemo}
        onChange={(e, value) => changeItemData('building', value)}
        label="Здание целиком/помещение/иное"
      />
      <Box>
        <TextTitle>Тип владения</TextTitle>
        <CustomRadioGroup
          required
          validImmediately
          disabled={isDemo}
          onChange={(e, value) => changeItemData('ownership_type', value)}
          value={building.ownership_type}
          options={[
            { label: 'Аренда', value: OWNERSHIP_TYPE_TRANSLATE.rent },
            { label: 'Собственность', value: OWNERSHIP_TYPE_TRANSLATE.own },
          ]}
        />
      </Box>
      {building.ownership_type === OWNERSHIP_TYPE_TRANSLATE.rent && (
        <Box sx={{ ...flexGap2, mb: 2 }}>
          <Text sx={{ fontWeight: 'bold' }} big>Договор аренды (дата/номер)</Text>
          <Box sx={flexRowGap2}>
            <CustomDatePicker
              validImmediately
              canBeZero
              label="Дата"
              disabled={isDemo}
              value={building.lease_contract_date}
              onChange={(e, value) => changeItemData('lease_contract_date', value)}
            />
            <CustomInput
              validImmediately
              debounceTime={0}
              label="Номер"
              disabled={isDemo}
              value={building.lease_contract_number}
              onChange={(e, value) => changeItemData('lease_contract_number', value)}
            />
          </Box>
        </Box>
      )}
      <AsyncAutocomplete
        canBeZero
        validImmediately={!compareId(building?.enterprise_activity_types, 0)}
        multiple
        debounceTime={0}
        disabled={isDemo}
        sx={fullWidth}
        request={(params) => getReferencesRequest({
          url: ENTERPRISE_ACTIVITY_TYPES_ENUM,
          params,
          filter: (options) => {
            const filteredOptions = options
              .filter((activityType) => subjectAttributes?.enterprise_activity_type_ids?.includes?.(Number(activityType.id)));
            return formatEnumToSelectOption(filteredOptions);
          },
        })}
        optionField={DEFAULT_AUTOCOMPLETE_FIELD}
        label="Наименование здания/помещения/сооружения (по виду деятельности)"
        value={building.enterprise_activity_types}
        onChange={(e, value) => changeItemData('enterprise_activity_types', value)}
      />
      <CustomInput
        validImmediately
        debounceTime={0}
        disabled={isDemo}
        type="number"
        label="Общая площадь помещения (кв,м)"
        value={building.total_area}
        onChange={(e, value) => changeItemData('total_area', value)}
      />
      <CustomAccordion title="Отходы">
        <Box sx={flexGap2}>
          {!isDemo && (
            <AddButton
              variant="outlined"
              onClick={() => {
                changeItemFormByField({
                  field: `form1.items.${buildingIndex}.waste_products`,
                  value: [...building.waste_products, getInitialWasteProduct()],
                });
              }}
            >
              Добавить отход
            </AddButton>
          )}
          <NavTabs
            tabsComponents={wasteTabs}
            setTab={setWasteTab}
            tab={wasteTab}
          />
          {building.waste_products.map((waste_product, index) => {
            if (waste_product.randId !== wasteTab) return null;

            const onDeleteWaste = async () => {
              if (waste_product.id) {
                const deletedId = await deleteForm1Entity({ wasteId: waste_product.id });

                if (!deletedId) return;
              }

              const cloneWasteProducts = building.waste_products.slice();
              cloneWasteProducts.splice(index, 1);

              changeItemFormByField({
                field: `form1.items.${buildingIndex}.waste_products`,
                value: cloneWasteProducts,
                type: 'delete',
              });
            };

            const changeWasteProduct = (field, value) => changeItemData(
              `waste_products.${index}.${field}`,
              value,
              index,
            );

            return (
              <Box sx={flexGap2} key={waste_product.randId}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Text>
                    Отход №
                    {index + 1}
                  </Text>
                  {!isDemo && building.waste_products.length > 0 && (
                    <DeleteButton onClick={() => openDeleteModal(onDeleteWaste, index, 'Отход')}>
                      Удалить отход
                    </DeleteButton>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" gap={3}>
                  <AsyncAutocomplete
                    validImmediately
                    canBeZero
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fullWidth}
                    request={getWasteTypesRequest}
                    value={waste_product.code}
                    optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                    label="Код отходов по ФККО"
                    onChange={(e, value) => changeWasteProduct('code', value)}
                  />
                  {!waste_product.code?.hazard_class
                    && <Alert severity="error">Необходимо указать класс опасности в поле ниже</Alert>}
                  <Selector
                    validImmediately
                    disabled={isDemo || !waste_product.code?.id || (waste_product.code?.hazard_class && !compareId(waste_product?.code?.code?.at(-1), 0))}
                    sx={fullWidth}
                    request={(params) => getApplicationEnumsRequest(HAZARD_CLASS_ENUM, params)}
                    value={{
                      label: waste_product.code?.hazard_class_text,
                      value: waste_product.code?.hazard_class,
                      id: waste_product.code?.hazard_class,
                    }}
                    optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                    label="Класс опасности"
                    onChange={(e, value) => changeWasteProduct('code', {
                      ...waste_product.code,
                      hazard_class: value.id,
                      hazard_class_text: value.label,
                    })}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    label="Количество образованных отходов"
                    disabled={isDemo}
                    type="number"
                    value={waste_product.quantity}
                    onChange={(e, value) => changeWasteProduct('quantity', value)}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </CustomAccordion>
      <CustomAccordion title="Площадки">
        <Box sx={flexGap2}>
          {!isDemo && (
            <AddButton
              variant="outlined"
              onClick={() => {
                changeItemFormByField({
                  field: `form1.items.${buildingIndex}.platforms`,
                  value: [...building.platforms, getInitialPlatforms()],
                });
              }}
            >
              Добавить площадку
            </AddButton>
          )}
          <NavTabs
            tabsComponents={platformTabs}
            setTab={setPlatformTab}
            tab={platformTab}
          />
          {building.platforms.map((platform, index) => {
            if (platformTab !== 'all' && platform.randId !== platformTab) return null;

            const changePlatform = (field, value) => changeItemData(`platforms.${index}.${field}`, value, undefined, index);

            const onDeletePlatform = async () => {
              if (platform.id) {
                const deletedId = await deleteForm1Entity({ areaId: platform.id });

                if (!deletedId) return;
              }

              const clonePlatforms = building.platforms.slice();
              clonePlatforms.splice(index, 1);

              changeItemFormByField({
                field: `form1.items.${buildingIndex}.platforms`,
                value: clonePlatforms,
                type: 'delete',
              });
            };

            return (
              <CadastreForm1Platform
                key={platform.randId}
                platform={platform}
                index={index}
                onDeletePlatform={onDeletePlatform}
                buildingIndex={buildingIndex}
                openDeleteModal={openDeleteModal}
                isDemo={isDemo}
                building={building}
                fullWidth={fullWidth}
                enterpriseAccumulationParam={enterpriseAccumulationParam}
                deleteForm1Entity={deleteForm1Entity}
                changeItemFormByField={changeItemFormByField}
                objectTypeIds={objectTypeIds}
                setCreateObjectIndex={setCreateObjectIndex}
                changePlatform={changePlatform}
              />
            );
          })}
        </Box>
      </CustomAccordion>
    </Box>
  );
}

export default memo(CadastreForm1Building);
