/* eslint-disable */
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { CURRENT_APP_VER } from '../appVersion';
import { RUBY_REST_URL } from '../constants';
import { compareId, formatEntityAttributes, readToken, writeToken } from '../helpers';
import {
  checkIsControlOrigin,
  checkIsPublicCadastreOrigin,
  checkIsPublicRKO47Origin,
} from '../helpers/browserLocation';
import { formatEnumFieldData } from '../helpers/entityHelper';
import { deleteUndefinedFromObject, getParamsFromObject } from '../helpers/objectHelper';
import { PUBLIC_APPLICATION_ROUTE } from '../constants/routes';

const LOGOUT_ERRORS = ['signature has expired', 'wrong scope', 'вам необходимо войти в систему или зарегистрироваться.', 'nil user', 'Invalid segment encoding'];
const NOT_LOGOUT_ERRORS = ['Неправильный Email или пароль.'];

const getMainUrl = () => {
  let url = process.env.REACT_APP_BASE_URL

  if (checkIsControlOrigin() || checkIsPublicCadastreOrigin()) url = process.env.REACT_APP_PUBLIC_BASE_URL;

  return url;
}

const API = axios.create({
  baseURL: getMainUrl(),
  headers: { 'Content-Type': 'application/json' },
});

// Custom retry delay
axiosRetry(API, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 5000; // 120 sec to retrying request
  },
  retryCondition: (error) => {
    // if retry condition is not specified, by default idempotent requests are retried
    return error?.response?.status === 503;
  },
});

API.interceptors.request.use(
  (config) => {
    const token = readToken();

    if (typeof token === 'string') config.headers.Authorization = token;

    return config;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (res) => {
    const newToken = res?.headers?.authorization;

    const partition = res?.data?.data?.attributes?.partition;
    const additional_partition = res?.data?.data?.attributes?.additional_partition;

    if (!window.location.pathname.includes(PUBLIC_APPLICATION_ROUTE) && newToken
      && ((checkIsControlOrigin() && compareId(partition, 'cadastre') && !compareId(additional_partition, 'control')) ||
        checkIsPublicCadastreOrigin() && compareId(partition, 'control') && !compareId(additional_partition, 'cadastre'))
    ) {
      return Promise.reject({ response: { data: { message: 'Нет прав для входа в систему' } } })
    }

    if (newToken) writeToken(newToken);

    return res;
  },
  async (err) => {
    const notErrorVehiclePositions = !err?.config?.url?.includes?.('_positions')

    if (
      !window.location.pathname.includes(PUBLIC_APPLICATION_ROUTE) &&
      !NOT_LOGOUT_ERRORS.includes(err?.response?.data?.error) &&
      notErrorVehiclePositions &&
      (LOGOUT_ERRORS.includes(err?.response?.data?.error?.toLowerCase?.())
        || compareId(err?.response?.status, 401)
        || err?.message === 'Network Error')
    ) {
      err.logout = true;
    }
    return Promise.reject(err);
  },
);

const addJsonToUrl = (params, restUrl = RUBY_REST_URL) => {
  return `/api${params.url}${restUrl || ''}`;
}

const defaultOptions = {
  restUrl: RUBY_REST_URL,
  isEntityAttributes: false,
};

const onUploadProgress = (changeLoadingIndicator) => function (progressEvent) {
  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  changeLoadingIndicator(percentCompleted);
  if (percentCompleted === 100) {
    changeLoadingIndicator(null);
  }
};

export const METHODS_API = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};

export const defaultApi = API;

let cachedData = {};
const getNewTime = () => (new Date()).getTime();

const api = (params, {
  restUrl,
  shouldCache = false,
  isEntityAttributes,
  setTableStore,
  enum_field,
  filter,
  isMultipartForm,
  changeLoadingIndicator,
  controller,
} = defaultOptions) => {
  if (controller) {
    controller.current = new AbortController();
  }

  const axiosConfig = {};

  if (controller?.current?.signal) axiosConfig.signal = controller.current.signal;
  if (isMultipartForm) axiosConfig.headers = { 'Contend-Type': 'multipart/form-data' };
  if (changeLoadingIndicator) axiosConfig.onUploadProgress = onUploadProgress(changeLoadingIndicator);

  const filteredParams = deleteUndefinedFromObject(params?.params);

  try {
    delete filteredParams.controller
  } catch (e) {
    console.error('no controller');
  }

  const mainUrl = addJsonToUrl(params, restUrl);
  const cacheUrl = `${mainUrl}${getParamsFromObject({ object: filteredParams })}`;

  const isGetMethod = params.method === undefined || params.method === METHODS_API.GET;

  // return cache if we have it
  if (isGetMethod && shouldCache) {
    // clear cache if we have a lot of keys
    if (Object.keys(cachedData).length > 50) {
      cachedData = {}
    }

    const timeDate = getNewTime();
    const minutes = 1;
    const seconds = 60;
    const miliseconds = 1000;
    const oneMinute = minutes * seconds * miliseconds;

    if (cachedData[cacheUrl]?.response && (timeDate - cachedData[cacheUrl].time) < oneMinute) {
      return new Promise(resolve => resolve(cachedData[cacheUrl].response));
    } else {
      cachedData[cacheUrl] = {};
    }
  }

  let mainResponse;

  return API({
    ...params,
    params: { ...filteredParams },
    headers: { ...params?.headers, ...axiosConfig.headers, 'APP_VER': CURRENT_APP_VER },
    url: mainUrl,
  })
    .then((response) => {
      mainResponse = response;

      if (isEntityAttributes) {
        const attributes = formatEntityAttributes(response, { setTableStore });

        mainResponse = attributes;
      }
      else if (enum_field) {
        const enums = formatEnumFieldData(response.data, enum_field).map((option) => ({
          ...option,
          label: `${option.label[0].toUpperCase()}${option.label.slice(1)}`,
        }));

        mainResponse = enums;
      }
      
      if (filter) mainResponse = filter(mainResponse);

      // cache data
      if (isGetMethod && shouldCache) {
        cachedData[cacheUrl].response = mainResponse;
        cachedData[cacheUrl].time = getNewTime();
      }

      return mainResponse;
    });
};

export default api;

const getUrlWithId = (url, id, rest) => `${url}${id ? `/${id}` : ''}${rest || ''}`;

export const commonApi = (url, entity) => ({
  data, params, method, id, rest, isFormData, isEntityAttributes = true
} = {
    method: METHODS_API.GET, data: undefined, id: undefined, params: undefined, rest: undefined,
  }, setTableStore) => {
  const dataObject = !isFormData && entity ? { [entity]: data } : data;
  const bodyData = data ? dataObject : undefined;

  const filteredParams = deleteUndefinedFromObject(params);

  return api({
    url: getUrlWithId(url, id, rest),
    method,
    params: method === METHODS_API.GET || method === undefined
      ? { total: 1000, ...filteredParams }
      : undefined,
    data: bodyData,
  }, { isEntityAttributes, setTableStore });
};