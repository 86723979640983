// @ts-nocheck
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { BACKGROUND_LIGHT } from '../../constants/colors';
import { compareId } from '../../helpers';
import { getError } from '../../helpers/formValidator';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import { TextMain } from '../Text';

export const DEFAULT_AUTOCOMPLETE_OPTION = {
  label: '',
  value: '',
  id: '',
};

export const DEFAULT_AUTOCOMPLETE_FIELD = {
  label: 'label',
  value: 'value',
  id: 'id',
};

const autocompleteSx = {
  width: 300,
  '& .MuiInputLabel-root': {
    pr: 3,
  },
  '&.error': {
    fieldset: {
      border: ({ palette }) => `1px solid ${palette.error.main}`,
    },
  },
  '.MuiInputBase-root.MuiInputBase-formControl': {
    borderRadius: '8px',
    background: BACKGROUND_LIGHT,
  },
  fieldset: {
    border: 'none',
  },
};

const multiplyAutocompleteTextareaStyle = {
  '& textarea': {
    width: '100% !important',
  },
};

const disableHoverEffect = { background: 'initial !important' };

function DefaultNoOptionComponent() {
  return <TextMain>Нет совпадений</TextMain>;
}

function CustomAutocomplete({
  required,
  onChange,
  value,
  setIsLoading,
  name,
  asyncSearch,
  noOptionComponent,
  validImmediately,
  options,
  fields,
  label,
  filterOptions,
  placeholder,
  sx,
  containerSx,
  error,
  loading,
  id,
  multiple,
  disabled,
  useDefaultFilter,
  canBeZero = false,
  selectFirstOption,
  multiline = true,
  debounceTime = 500,
  ...rest
}) {
  const [innerValue, setInnerValue] = useState('');
  const [isSelectedFirstOption, setIsSelectedFirstOption] = useState(false);
  const errorText = error || getError({ value: innerValue, validImmediately });
  const errorClass = (!disabled && errorText) ? 'error' : '';

  const innerFields = useMemo(() => ({
    ...DEFAULT_AUTOCOMPLETE_FIELD,
    ...fields,
  }), [fields]);

  const textInputValue = value?.[0] === '0' ? '0' : value?.[innerFields.label];

  const [isOpen, setIsOpen] = useState(false);

  const makeAsyncSearch = useDebouncedCallback((newValue) => {
    asyncSearch(newValue);
  }, debounceTime);

  const changeValue = (
    e,
    newValue,
    index,
    option,
    typeChange,
    isMultiple,
  ) => {
    if (!isMultiple) {
      if (e) {
        e.target.name = name;
        let currentOption = option || (typeof index === 'number' ? options[index] : value);
        if (newValue === '') {
          currentOption = {
            [innerFields.label]: '',
            [innerFields.value]: '',
            [innerFields.id]: '',
          };
        }
        onChange?.(e, { ...currentOption, [innerFields.label]: newValue });
      } else return;
    }

    if (asyncSearch && !(typeChange === 'select' && multiple)) {
      makeAsyncSearch({ search: newValue || '' });
    }
  };

  const debounceChangeValue = useDebouncedCallback(changeValue, 150);

  const onInputChange = (e, newValue, index, option, typeChange) => {
    if (canBeZero && multiple && newValue === '0') {
      onChange?.(e, [newValue]);
      setInnerValue(newValue);

      return;
    }

    if (multiple && typeChange === 'select') return;

    if (debounceTime) {
      debounceChangeValue(e, newValue, index, option, typeChange, multiple);
    } else {
      changeValue(e, newValue, index, option, typeChange, multiple);
    }

    setInnerValue(newValue);
  };

  const innerFilterOptions = (innerOptions) => {
    let filteredOptions = innerOptions;

    if (filterOptions) {
      filteredOptions = filterOptions(innerOptions, textInputValue);
    } else if (!asyncSearch || useDefaultFilter) {
      filteredOptions = innerOptions.filter((item) => {
        const filterLowerCase = item?.[innerFields.label]?.toLowerCase();
        return filterLowerCase?.includes?.(textInputValue?.toLowerCase?.() || '');
      });
    }

    if (!loading && !filteredOptions.length) {
      filteredOptions.push({
        noOptionComponent: noOptionComponent || <DefaultNoOptionComponent />,
      });
    }

    return filteredOptions;
  };

  const checkOptionEqual = (
    option,
    nextOption,
  ) => option?.[innerFields.id] === nextOption?.[innerFields.id];

  useEffect(() => {
    if (!multiple && textInputValue !== innerValue) setInnerValue(textInputValue);
    if (multiple && textInputValue === '0' && canBeZero && innerValue !== '0') setInnerValue('0');
    // eslint-disable-next-line
  }, [textInputValue, multiple]);

  useEffect(() => {
    if (selectFirstOption && options.length && !isSelectedFirstOption) {
      setIsSelectedFirstOption(true);

      const optionIndex = typeof selectFirstOption !== 'boolean'
        ? options.findIndex((option) => compareId(option[innerFields.id], selectFirstOption)) || 0
        : 0;

      onInputChange({ target: { name } }, options[optionIndex][innerFields.label], optionIndex);
    }
    /* eslint-disable-next-line */
  }, [selectFirstOption, isSelectedFirstOption, options]);

  const { isMobileTablet } = useCustomMediaQuery();

  const multiplyAutocompleteTextarea = useMemo(() => (
    multiple ? multiplyAutocompleteTextareaStyle : {}
  ), [multiple]);

  const changeMultiply = (e, selectedOptions, actionName) => {
    if (multiple) {
      const finalOptions = canBeZero && selectedOptions.includes('0') ? selectedOptions.filter((item) => item !== '0') : selectedOptions;
      onChange({ ...e, target: { ...e.target, name } }, finalOptions, actionName);
    }
  };

  return (
    <Box sx={containerSx}>
      <Autocomplete
        multiple={multiple}
        disabled={Boolean(disabled)}
        freeSolo
        clearOnBlur={!canBeZero && multiple}
        className={errorClass}
        clearOnEscape={!canBeZero && multiple}
        clearIcon={textInputValue === '' ? null : undefined}
        value={value}
        id={id}
        sx={{ ...autocompleteSx, ...sx, ...multiplyAutocompleteTextarea }}
        open={isOpen}
        disableCloseOnSelect={multiple}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        isOptionEqualToValue={checkOptionEqual}
        getOptionLabel={(option) => option?.[innerFields.label] || ''}
        options={options || []}
        loading={loading}
        loadingText="Загрузка..."
        filterOptions={innerFilterOptions}
        onChange={changeMultiply}
        onInputChange={onInputChange}
        inputValue={innerValue || ''}
        renderTags={(tagValue, getTagProps) => {
          if (tagValue[0] === '0') return null;

          return (
            tagValue.map((option, index) => (
              <Chip
                label={option[innerFields.label]}
                {...getTagProps({ index })}
              />
            ))
          );
        }}
        renderOption={(props, option) => (
          <Typography
            component="li"
            {...props}
            sx={option?.noOptionComponent && disableHoverEffect}
            onClick={(e) => {
              if (option?.noOptionComponent) return;

              props.onClick(e, props.key);
              onInputChange(e, props.key, props['data-option-index'], option, 'select');
            }}
            key={option[innerFields.id]}
          >
            {option?.noOptionComponent || option[innerFields.label]}
          </Typography>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            {...rest}
            required={required}
            onFocus={() => {
              if (asyncSearch) {
                asyncSearch({ search: innerValue || '' });
                if (setIsLoading) setIsLoading(true);
              }
            }}
            placeholder={placeholder}
            error={Boolean(errorText)}
            helperText={errorText}
            size={isMobileTablet ? 'small' : undefined}
            label={label}
            multiline={multiline}
          />
        )}
      />
    </Box>
  );
}

export default memo(CustomAutocomplete);
