// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Box } from '@mui/material';
import { addAddressRequest, addressByCoordinatesRequest } from '../../api/addressApi';
import { getReferencesRequest } from '../../api/referenceApi';
import { OKTMOS_URL } from '../../constants/urls';
import { formValidator } from '../../helpers';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { formatLatLngToPostGisPoint } from '../../helpers/mapHelper';
import { formatAddressString } from '../../helpers/stringHelper';
import { useApi } from '../../hooks';
import { AlertRequiredFields } from '../Inputs/AlertRequiredFields';
import { DEFAULT_AUTOCOMPLETE_OPTION } from '../Inputs/CustomAutocomplete';
import ModalCard from './ModalCard';
import AddressPointMap from '../../pages/Cadastr/AddressPointMap';

const initialAddressData = {
  region: '',
  street: '',
  district: '',
  house: '',
  oktmo: DEFAULT_AUTOCOMPLETE_OPTION,
  hull_number: '',
  building_number: '',
  latitude: '',
  longitude: '',
  city: '',
  cadastral_number: '',
};

const addressFrame = [
  {
    id: 'region_data',
    title: 'Данные области',
    inputFields: [
      { id: 'region-help', text: 'Пример: Ленинградская область' },
      { id: 'region', label: 'Регион', required: true },
      { id: 'district-help', text: 'Пример: Всеволожский район' },
      { id: 'district', label: 'Район', required: true },
      {
        id: 'oktmo',
        label: 'ОКТМО',
        required: true,
        type: 'autocomplete',
        error: (error) => error.oktmo,
        fields: { label: 'label' },
        additionalFilter: (options) => options.map((option) => ({
          ...option,
          label: `${option.code} ${option.name}`,
        })),
        request: (params) => getReferencesRequest({ url: OKTMOS_URL, params }),
      },
    ],
  },
  {
    id: 'city_data',
    title: 'Данные по городу',
    inputFields: [
      { id: 'city-help', text: 'Пример: Сертолово' },
      { id: 'city', label: 'Город/Поселение', required: true },
      { id: 'street_help', text: 'Пример: Школьная улица. При отсутствии ставим прочерк' },
      { id: 'street', label: 'Улица', required: true },
      { id: 'house_help', text: 'Пример: 27А. При отсутствии ставим прочерк' },
      { id: 'house', label: 'Дом', required: true },

      { id: 'hull_number-help', text: 'Пример: 27А. При отсутствии ставим прочерк' },
      {
        id: 'hull_number',
        label: 'Номер корпуса',
        required: true,
        multiline: true,
      },

      { id: 'building_number-help', text: 'Пример: 27А. При отсутствии ставим прочерк' },
      {
        id: 'building_number',
        label: 'Номер строения',
        required: true,
        multiline: true,
      },
    ],
  },
  {
    id: 'coordinates_data',
    title: 'Координаты адреса',
    inputFields: [
      { id: 'latitude_help', text: 'Пример: 12.34567' },
      { id: 'latitude_help-miss', text: 'Заполняется при отсутствии улицы и номера дома' },
      { id: 'latitude', label: 'Широта', required: ({ showMap }) => showMap },
      { id: 'longitude_help', text: 'Пример: 12.34567' },
      { id: 'longitude_help-miss', text: 'Заполняется при отсутствии улицы и номера дома' },
      { id: 'longitude', label: 'Долгота', required: ({ showMap }) => showMap },
      { id: 'cadastral_number_help', text: 'Пример: 47:21:0201007' },
      { id: 'cadastral_number_help-miss', text: 'Заполняется при отсутствии улицы и номера дома' },
      { id: 'cadastral_number', label: 'Кадастровый номер' },
    ],
  },
];

export default function ModalAddAddress({
  close,
  setNewAddress,
  addressInfo,
  showMap = false,
  modalTitle,
}) {
  const [error, setError] = useState({});
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [addressData, setAddressData] = useState({ ...initialAddressData, ...addressInfo });

  const checkErrors = (newData) => {
    const ignoreInputs = ['cadastral_number', 'id', 'value'];

    if (!showMap) ignoreInputs.push(...['latitude', 'longitude']);

    const mainData = newData || addressData;

    const {
      hasErrors,
      validField,
    } = formValidator({
      form: { ...mainData, oktmo: mainData.oktmo?.id },
      ignoreInputs,
    });

    setError(validField);

    return hasErrors;
  };

  const createAddress = async () => {
    const hasErrors = checkErrors();

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    const requestAddress = {
      region: addressData.region,
      street: addressData.street,
      district: addressData.district,
      hull_number: addressData.hull_number,
      building_number: addressData.building_number,
      city: addressData.city,
      oktmo_id: addressData.oktmo.id,
    };

    if (addressData.cadastral_number) {
      requestAddress.cadastral_number = addressData.cadastral_number;
    }
    if (addressData.house) requestAddress.house = addressData.house;
    if (addressData.latitude && addressData.longitude) {
      requestAddress.place = formatLatLngToPostGisPoint(
        addressData.latitude,
        addressData.longitude,
      );
    }

    const createdAddress = await addAddressRequest(requestAddress);

    if (setNewAddress) {
      setNewAddress({
        ...createdAddress,
        value: createdAddress.id,
        label: formatAddressString(createdAddress),
      });
    }

    close(true);
  };

  const {
    makeRequest: onCreateAddress,
    isLoading,
  } = useApi({
    request: createAddress,
    successMessage: 'Адрес успешно создан',
  });

  const onChange = (name) => (e, value) => {
    setError((prevState) => ({
      ...prevState,
      [name]: '',
    }));
    setAddressData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (addressInfo?.oktmo) {
      (async () => {
        const currentOktmos = await getReferencesRequest({
          url: OKTMOS_URL,
          params: { search: addressInfo.oktmo },
          filter: (options) => options.map((option) => ({
            ...option,
            label: `${option.code} ${option.name}`,
          })),
        });

        if (currentOktmos?.length) {
          onChange('oktmo')({}, currentOktmos[0]);
        }
      })();
    }
  }, [addressInfo]);

  const { defaultRequest: getAddressApi } = useApi(({
    request: addressByCoordinatesRequest,
  }));

  const onClickMap = async (latlng) => {
    setIsLoadingMap(true);

    const data = await getAddressApi({ latitude: latlng.lat, longitude: latlng.lng, service: 'geocoder' });

    const mainData = data?.data?.data;

    if (mainData) {
      let value = '';

      if (mainData.city_with_type) value += mainData.city_with_type;
      if (mainData.street_with_type) value += ` ${mainData.street_with_type}`;
      else if (mainData.street) value += ` ${mainData.street}`;
      if (mainData.house_type_full) value += ` ${mainData.house_type_full}`;
      if (mainData.house) value += ` ${mainData.house}`;

      const replacedLabel = value;

      const {
        region,
        region_type_full,
        area_with_type: district,
        street_with_type: streetWithType,
        street,
        house,
        block,
        geo_lat: latitude,
        geo_lon: longitude,
        settlement: city,
        okato,
        oktmo,
        stead_cadnum: cadastral_number,
      } = mainData || {};

      const additionalData = {};

      if (mainData.block_type_full === 'корпус') additionalData.hull_number = block;
      if (mainData.block_type_full === 'строение') additionalData.building_number = block;

      if (oktmo) {
        const oktmoData = await getReferencesRequest({ url: OKTMOS_URL, params: { search: oktmo } });

        if (oktmoData) {
          additionalData.oktmo = oktmoData.map((option) => ({
            ...option,
            label: `${option.code} ${option.name}`,
          }))?.[0];
        }
      }

      setAddressData((prev) => {
        const newData = {
          ...prev,
          label: replacedLabel,
          region: `${region || ''}${region_type_full ? (` ${region_type_full}`) : ''}`,
          district,
          street: streetWithType || street,
          house,
          latitude,
          longitude,
          city,
          okato,
          cadastral_number,
          ...additionalData,
        };

        checkErrors(newData);

        return newData;
      });
    }

    setIsLoadingMap(false);
  };

  const fullMapStyle = useMemo(() => (showMap ? { width: '100vw' } : undefined), [showMap]);

  return (
    <ModalCard
      open
      containerSx={fullMapStyle}
      inputs={addressFrame}
      inputData={{ ...addressData, showMap }}
      close={close}
      isLoading={isLoading || isLoadingMap}
      error={error}
      additionalTopContainer={<AlertRequiredFields />}
      additionalComponents={showMap && (
        <Box width={600} height={600}>
          <Alert sx={{ mt: 1 }} color="warning" severity="warning">Необходимо установить точку контейнерной площадки на карте</Alert>
          <AddressPointMap
            objectiveAddress={addressData}
            address={addressData}
            isLoading={isLoadingMap}
            onClickMap={onClickMap}
          />
        </Box>
      )}
      handleChange={onChange}
      title={`Добавление нового адреса${modalTitle ? ` ${modalTitle}` : ''}`}
      secondButtonProp={{
        label: 'Добавить адрес',
        onClick: onCreateAddress,
      }}
    />
  );
}
